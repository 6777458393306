import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from "@/store";
import Paginate from 'vuejs-paginate'
import VueSweetalert2 from 'vue-sweetalert2';
import i18n from './plugins/i18n'
import 'sweetalert2/dist/sweetalert2.min.css';
import Loading from 'vue-loading-overlay';
import StarRating from 'vue-star-rating'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import HighchartsVue from "highcharts-vue";
// Init plugin
Vue.use(Loading);
Vue.use(VueSweetalert2);
Vue.use(HighchartsVue);
Vue.component('star-rating', StarRating)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('paginate', Paginate)

Vue.prototype.$axios = axios
Vue.prototype.$cookies = VueCookies

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
