




































































import { Component, Mixins } from "vue-property-decorator";
import NavbarComponent from "./Navbar.component";
@Component
export default class Navbar extends Mixins(NavbarComponent) {}
