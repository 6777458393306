import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import moment from 'moment';
import { GET_PROFILE_AGENT } from "../../../../api/account"
import i18n from '@/plugins/i18n'

Vue.use(VueCookies)
@Component({
    computed: {
        lang: {
            get: function () {
                return this.$store.state.locale
            }
        }
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        sCurrency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})
default class Navbar extends Vue {

    private cookieData: any = Vue.$cookies.get("login")
    private role: string
    private typeBusiness: string

    private profile: any = {}
    public baseURL = '/img/logo_2.jpg'
    public baseLanguage = {
        th: '/img/flag/th.png',
        en: '/img/flag/en.png'
    }
    constructor() {
        super();
        this.role = ""
        this.typeBusiness = ""
    }

    public logout() {
        this.$cookies.remove('login');
        localStorage.removeItem("vuex");
        window.location.replace('/login')
    }

    async mounted() {
        if (this.cookieData) {
            this.role = this.cookieData.roles
            this.typeBusiness = this.cookieData.typeBusiness
            const result = await GET_PROFILE_AGENT(this.cookieData.realUsername)
            this.profile = result[0]
        }
    }

    public changeLanguage(val: string) {
        this.$store.dispatch('changeLocale', val)
        i18n.locale = val
    }
}
export default Navbar