var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cookieData
    ? _c(
        "div",
        {
          staticClass: "side-nav white fixed wide side-nav-light z-depth-0",
          attrs: { id: "slide-out" }
        },
        [
          _c(
            "ul",
            { staticClass: "custom-scrollbar_", attrs: { id: "accordion" } },
            [
              _c("li", [
                _c(
                  "ul",
                  {
                    staticClass: "accordion collapsible collapsible-accordion"
                  },
                  [
                    _c("li", { staticClass: "menuMain" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "collapsible-header waves-effect text-bold",
                          class: { active: _vm.routeName == "Home" },
                          attrs: {
                            href: _vm.$router.resolve({ name: "Home" }).href,
                            id: "home"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "sv-slim-icon fas fa-dashboard"
                          }),
                          _c("label", { staticClass: "main" }, [
                            _vm._v(_vm._s(_vm.$t("HOME.DASHBOARD")))
                          ])
                        ]
                      )
                    ]),
                    _vm.mainUser || _vm.subAccountMenus.profile
                      ? _c(
                          "li",
                          {
                            staticClass: "menuMain",
                            attrs: { id: "account-menu1" }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "collapsible-header waves-effect text-bold",
                                class: {
                                  active:
                                    _vm.nav["profile"] ||
                                    _vm.routeName == "Profile" ||
                                    _vm.routeName == "ChangePassword"
                                },
                                staticStyle: { width: "100%" },
                                attrs: {
                                  id: "account-menu",
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#account",
                                  "aria-expanded": "false",
                                  "aria-controls": "management"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleAccordion("profile")
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "sv-slim-icon fas fa-member"
                                }),
                                _c("label", { staticClass: "main" }, [
                                  _vm._v(_vm._s(_vm.$t("HOME.ACCOUNT")))
                                ]),
                                _c("i", {
                                  staticClass: "fas fa-angle-down rotate-icon"
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "collapse",
                                class: {
                                  show:
                                    _vm.routeName == "Profile" ||
                                    _vm.routeName == "ChangePassword"
                                },
                                attrs: {
                                  id: "account",
                                  "data-parent": "#accordion"
                                }
                              },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "waves-effect text-bold",
                                        attrs: {
                                          id: "profile-item",
                                          href: _vm.$router.resolve({
                                            name: "Profile"
                                          }).href
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "sv-normal submain" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("HOME.PROFILE"))
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "waves-effect text-bold",
                                        attrs: {
                                          id: "change-password-item",
                                          href: _vm.$router.resolve({
                                            name: "ChangePassword"
                                          }).href
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "sv-normal submain" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("HOME.CHANGE_PASSWORD")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.mainUser || _vm.subAccountMenus.management
                      ? _c("li", { staticClass: "menuMain" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "collapsible-header waves-effect text-bold",
                              class: {
                                active:
                                  _vm.nav["management"] ||
                                  _vm.routeName == "AddAgent" ||
                                  _vm.routeName == "EditAgent" ||
                                  _vm.routeName == "AddMember" ||
                                  _vm.routeName == "EditMember" ||
                                  _vm.routeName == "AgentList" ||
                                  _vm.routeName == "SubAccountList" ||
                                  _vm.routeName == "AddSubAccount" ||
                                  _vm.routeName == "EditSubAccount"
                              },
                              staticStyle: { width: "100%" },
                              attrs: {
                                id: "managment-menu",
                                type: "button",
                                "data-toggle": "collapse",
                                "data-target": "#management",
                                "aria-expanded": "false",
                                "aria-controls": "management"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleAccordion("management")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "sv-slim-icon fas fa-member",
                                attrs: { id: "s-managment" }
                              }),
                              _c("label", { staticClass: "main" }, [
                                _vm._v(_vm._s(_vm.$t("HOME.MANAGEMENT")) + " ")
                              ]),
                              _c("i", {
                                staticClass: "fas fa-angle-down rotate-icon"
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "collapse",
                              class: {
                                show:
                                  _vm.routeName == "AddAgent" ||
                                  _vm.routeName == "EditAgent" ||
                                  _vm.routeName == "AddMember" ||
                                  _vm.routeName == "EditMember" ||
                                  _vm.routeName == "AgentList" ||
                                  _vm.routeName == "SubAccountList" ||
                                  _vm.routeName == "AddSubAccount" ||
                                  _vm.routeName == "EditSubAccount"
                              },
                              attrs: {
                                id: "management",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("ul", [
                                (_vm.subAccountMenus.addAgent &&
                                  (_vm.role == "Company" ||
                                    _vm.role == "Shareholder" ||
                                    _vm.role == "Master Agent")) ||
                                (_vm.mainUser &&
                                  (_vm.role == "Company" ||
                                    _vm.role == "Shareholder" ||
                                    _vm.role == "Master Agent"))
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "waves-effect text-bold",
                                          attrs: {
                                            href: _vm.$router.resolve({
                                              name: "AddAgent"
                                            }).href,
                                            id: "add-agent-item"
                                          }
                                        },
                                        [
                                          (_vm.subAccountMenus.memberAgent &&
                                            _vm.role == "Company") ||
                                          (_vm.mainUser &&
                                            _vm.role == "Company")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sv-normal submain"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "HOME.ADD_SHAREHOLDER"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sv-normal submain"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("HOME.ADD_AGENT")
                                                    )
                                                  )
                                                ]
                                              )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.subAccountMenus.memberAgent || _vm.mainUser
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "waves-effect text-bold",
                                          attrs: {
                                            href: _vm.$router.resolve({
                                              name: "AgentList"
                                            }).href,
                                            id: "list-agent-item"
                                          }
                                        },
                                        [
                                          (_vm.subAccountMenus.memberAgent &&
                                            _vm.role == "Company") ||
                                          (_vm.mainUser &&
                                            _vm.role == "Company")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sv-normal submain"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("HOME.SHAREHOLDER")
                                                    )
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sv-normal submain"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "HOME.MANAGE_AGENT"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.sideActive
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "waves-effect text-bold",
                                          attrs: {
                                            id: "add-sub-account-item",
                                            href: _vm.$router.resolve({
                                              name: "AddSubAccount"
                                            }).href
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "sv-normal submain"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("HOME.ADD_SUB_ACCOUNT")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.sideActive
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "waves-effect text-bold",
                                          attrs: {
                                            id: "sub-account-item",
                                            href: _vm.$router.resolve({
                                              name: "SubAccountList"
                                            }).href
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "sv-normal submain"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("HOME.SUB_ACCOUNT")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.mainUser || _vm.subAccountMenus.report
                      ? _c("li", { staticClass: "menuMain" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "collapsible-header waves-effect text-bold",
                              class: {
                                active:
                                  _vm.nav["report"] ||
                                  _vm.routeName == "WinLose"
                              },
                              staticStyle: { width: "100%" },
                              attrs: {
                                id: "report-menu",
                                type: "button",
                                "data-toggle": "collapse",
                                "data-target": "#report",
                                "aria-expanded": "false",
                                "aria-controls": "report"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleAccordion("report")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "sv-slim-icon far fa-report"
                              }),
                              _c("label", { staticClass: "main" }, [
                                _vm._v(_vm._s(_vm.$t("HOME.REPORT")))
                              ]),
                              _c("i", {
                                staticClass: "fas fa-angle-down rotate-icon"
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "collapse",
                              class: { show: _vm.routeName == "WinLose" },
                              attrs: {
                                id: "report",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "waves-effect text-bold",
                                      attrs: {
                                        href: _vm.$router.resolve({
                                          name: "WinLose"
                                        }).href,
                                        id: "win-lose-item"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "sv-normal submain" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("HOME.WIN_LOSE"))
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "waves-effect text-bold",
                                      attrs: {
                                        href: _vm.$router.resolve({
                                          name: "TournamentWinLose"
                                        }).href,
                                        id: "win-lose-item"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "sv-normal submain" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("HOME.TOURNAMENT_WL"))
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.role == "Company"
                      ? _c("li", { staticClass: "menuMain" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "collapsible-header waves-effect text-bold",
                              attrs: {
                                href: _vm.$router.resolve({ name: "Feedback" })
                                  .href,
                                id: "rateus"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "sv-slim-icon far fa-payout-rates"
                              }),
                              _c("label", { staticClass: "main" }, [
                                _vm._v(_vm._s(_vm.$t("HOME.FEEDBACK")) + " ")
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.role == "Company"
                      ? _c("li", { staticClass: "menuMain" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "collapsible-header waves-effect text-bold",
                              attrs: {
                                href: _vm.$router.resolve({ name: "Retry" })
                                  .href,
                                id: "retry"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "sv-slim-icon far fa-file pl-1",
                                staticStyle: { color: "#d0a434" }
                              }),
                              _c("label", { staticClass: "main" }, [
                                _vm._v(_vm._s(_vm.$t("HOME.LOGREQUEST")))
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.role == "Company"
                      ? _c("li", { staticClass: "menuMain" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "collapsible-header waves-effect text-bold",
                              attrs: {
                                href: _vm.$router.resolve({
                                  name: "Outstanding"
                                }).href,
                                id: "outstanding"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "sv-slim-icon fas fa-play-circle pl-1",
                                staticStyle: { color: "#d0a434" }
                              }),
                              _c("label", { staticClass: "main" }, [
                                _vm._v(_vm._s(_vm.$t("HOME.OUTSTANDING")))
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.role == "Company"
                      ? _c("li", { staticClass: "menuMain" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "collapsible-header waves-effect text-bold",
                              attrs: {
                                href: _vm.$router.resolve({
                                  name: "Maintenance"
                                }).href,
                                id: "maintenance"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "sv-slim-icon fas fa-wrench pl-1",
                                staticStyle: { color: "#d0a434" }
                              }),
                              _c("label", { staticClass: "main" }, [
                                _vm._v(_vm._s(_vm.$t("HOME.MAINTENANCE")))
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.role == "Company"
                      ? _c("li", { staticClass: "menuMain" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "collapsible-header waves-effect text-bold",
                              attrs: {
                                href: _vm.$router.resolve({
                                  name: "TournamentList"
                                }).href,
                                id: "tournament"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "sv-slim-icon fas fa-list pl-1",
                                staticStyle: { color: "#d0a434" }
                              }),
                              _c("label", { staticClass: "main" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("TOURNAMENT.MENU_TOURNAMENT")) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            ]
          ),
          _c("div", { staticClass: "sidenav-bg rgba-white-strong" })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }