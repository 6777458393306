import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Model from './models/Model';
import i18n, { selectedLocale } from '@/plugins/i18n'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        locale: selectedLocale,
        todos: new Model(),
        agentPages: Array<unknown>(),
        reportPages: Array<unknown>(),
        profile: {},
    },
    plugins: [new VuexPersistence().plugin],
    mutations: {
        updateLocale(state, newLocale) {
            state.locale = newLocale
        },
        addRouteName(state, model: Model) {
            state.todos = model;

        },
        addAgent(state, agentModel) {
            state.agentPages.push(agentModel);
        },
        updateAgent(state, payload) {
            state.agentPages = payload;
        },
        resetAgent(state) {
            state.agentPages = [];
        },
        addReport(state, reportModel) {
            state.reportPages.push(reportModel);
        },
        updateReport(state, payload) {
            state.reportPages = payload;
        },
        resetReport(state) {
            state.reportPages = [];
        },
        addProfile(state, payload) {
            state.profile = payload;
        },
    },
    actions: {
        changeLocale({ commit }, newLocale) {
            i18n.locale = newLocale
            commit('updateLocale', newLocale)
        },
        addRouteName(context, model: Model) {
            context.commit('addRouteName', model);
        },
        addAgent(context, agentModel) {
            context.commit('addAgent', agentModel);
        },
        updateAgent(context, agentModel) {
            context.commit('updateAgent', agentModel);
        },
        resetAgent(context) {
            context.commit('resetAgent');
        },
        addReport(context, reportModel) {
            context.commit('addReport', reportModel);
        },
        updateReport(context, reportModel) {
            context.commit('updateReport', reportModel);
        },
        resetReport(context) {
            context.commit('resetReport');
        },
        addProfile(context, profile: any) {
            context.commit('addProfile', profile);
        }
    }
});

export default store