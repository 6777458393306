import Vue from 'vue'
import axios from 'axios'
import store from "@/store";


axios.interceptors.request.use(async (config) => {
    const data = Vue.$cookies.get("login");
    if (data) {
        // Nprogress.start()
        config.headers = {
            'auth-name': data.username,
            'auth-token': data.token
        }
    }
    return config;

}, (error) => {
    // Nprogress.done();
    console.log('error => ', error)
    throw error
});

axios.interceptors.response.use((response: any) => {
    const statusCode = response.data ? response.data.statusCode : null;
    const login = Vue.$cookies.get("login");
    switch (statusCode) {
        case 403:
            if(login)
            {
            Vue.$cookies.remove('login')
            Vue.swal({
                text: 'You have been signed out due to multiple login. Please sign in again.',
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: 'OK'
            });
            return window.location.replace('/login');
            }
            break
        case 408:
            Vue.swal({
                text: 'Connection Timeout.',
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: 'OK'
            });
            break
        default:
            return response
    }
});

export const axiosWithErrorHandler = async (method, url, body?): Promise<any> => {
    let result;
    const dataProfile: any = store.state.profile
    if (method == "GET") {
        result = await axios.get(url)
    } else if (method == "POST") {
        if(dataProfile.suspend)
        {
            Vue.swal({
                text: 'Account is suspend',
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: 'OK'
            });
        }
        else
        {
            result = await axios.post(url, body)
        }
    } else if (method == "PUT") {
        if(dataProfile.suspend)
        {
            
            Vue.swal({
                text: 'Account is suspend',
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: 'OK'
            });
        }
        else
        {
            result = await axios.put(url, body)
        }
    } else if (method == "DELETE") {
        if(dataProfile.suspend)
        {
            Vue.swal({
                text: 'Account is suspend',
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: 'OK'
            });
        }
        else
        {
            result = await axios.delete(url, body)
        }
    }
    return result
}
// export const httpClient = axios