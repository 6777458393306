var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cookieData
    ? _c("nav", { staticClass: "navbar navbar-light white p-0 fixed-top" }, [
        _c("a", { staticClass: "navbar-brand logo", attrs: { href: "" } }, [
          _c("img", { attrs: { src: _vm.baseURL, alt: "logo" } })
        ]),
        _c("div", { staticClass: "navbar-panel-box" }, [
          _c(
            "div",
            { staticClass: "navbar-panel d-flex justify-content-between" },
            [
              _c("div", { staticClass: "d-flex align-items-center nav-top" }, [
                _c("i", { staticClass: "far fa-user mr-2" }),
                _vm._v(" " + _vm._s(_vm.cookieData.username) + " "),
                _c("i", { staticClass: "fas fa-briefcase ml-4 mr-2" }),
                _vm._v(" " + _vm._s(_vm.role) + " "),
                _vm.role == "Shareholder"
                  ? _c("span", [_vm._v(" (" + _vm._s(_vm.typeBusiness) + ") ")])
                  : _vm._e(),
                _c("i", { staticClass: "far fa-money-bill-alt ml-4 mr-2" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("sCurrency")(
                        _vm.profile.creditLimit ? _vm.profile.creditLimit : 0
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "div",
                { staticClass: "d-flex align-items-center nav-top-links" },
                [
                  _c("div", { staticClass: "btn-group" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link dropdown-toggle",
                        attrs: {
                          href: "",
                          id: "lang-nav",
                          "data-toggle": "dropdown",
                          "aria-haspopup": "true",
                          "aria-expanded": "false"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.lang == "th-TH"
                                ? _vm.baseLanguage.th
                                : _vm.baseLanguage.en,
                            alt: "",
                            width: "20",
                            height: "20"
                          }
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.lang == "th-TH"
                                ? _vm.$t("LANG.TH")
                                : _vm.$t("LANG.EN")
                            )
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "dropdown-menu" }, [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { id: "thai-menu" },
                          on: {
                            click: function($event) {
                              return _vm.changeLanguage("th-TH")
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.baseLanguage.th,
                              alt: "",
                              width: "20",
                              height: "20"
                            }
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(_vm.$t("LANG.TH")))
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { id: "englist-menu" },
                          on: {
                            click: function($event) {
                              return _vm.changeLanguage("en-EN")
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.baseLanguage.en,
                              alt: "",
                              width: "20",
                              height: "20"
                            }
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(_vm.$t("LANG.EN")))
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "dropdown-divider" })
                    ])
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        target: "_blank",
                        href: "/document/User_Manual_Document.pdf"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-guide" }),
                      _vm._v(" " + _vm._s(_vm.$t("HOME.GUIDE")) + " ")
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: { href: "", id: "logout" },
                      on: { click: _vm.logout }
                    },
                    [
                      _c("i", { staticClass: "fas fa-sign-out-alt" }),
                      _vm._v(" " + _vm._s(_vm.$t("HOME.LOGOUT")) + " ")
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._m(0)
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "promo-panel d-flex justify-content-between" },
      [
        _c(
          "div",
          {
            staticClass: "promo-text",
            staticStyle: { color: "#000000 !important" }
          },
          [_c("p", [_vm._v("สวัสดีท่านสมาชิก")])]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }