var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed-sn" }, [
    _c(
      "div",
      { staticClass: "wrapper container" },
      [
        _c(
          "header",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dataActive,
                expression: "dataActive"
              }
            ],
            style: { display: _vm.displayComponent }
          },
          [
            _c("navBar", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dataActive,
                  expression: "dataActive"
                }
              ],
              style: { display: _vm.displayComponent }
            }),
            _c("sideBar", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dataActive,
                  expression: "dataActive"
                }
              ],
              style: { display: _vm.displayComponent }
            })
          ],
          1
        ),
        _c("router-view", {
          attrs: { loadingActive: { loadingActive: _vm.loadingActive } }
        }),
        _c("div", {
          staticClass: "modal fade",
          attrs: {
            id: "modal-main",
            role: "dialog",
            tabindex: "-1",
            "aria-hidden": "true"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }