// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/pricetag.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.purchase-license {\n  position: relative;\n  background-color: #356281;\n  color: white;\n  width: 100%;\n  height: 3em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 2px;\n  overflow: hidden;\n}\n.purchase-license .pricetag {\n  width: 100%;\n  height: 100%;\n  background-repeat: no-repeat;\n  background-position: -9% 50%;\n  background-size: 55%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: absolute;\n  top: 0;\n  left: 0;\n  opacity: 0.2;\n  z-index: 0;\n}\n.purchase-license span {\n  font-weight: 400;\n  font-size: 1.1em !important;\n  letter-spacing: 1px;\n  z-index: 1;\n}\n.remaining-days {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  font-size: 1.4em;\n  font-weight: 100;\n}\n", ""]);
// Exports
module.exports = exports;
