const APIURL = process.env.VUE_APP_API_URL

export default {
    API_URL: APIURL,
    MOCK_API_URL: 'http://localhost:9001',
    LOGIN: '/session/agent/login',
    RATE_US: '/account/rateus',
    COMMENT: '/account/comment',
    SUB_ACCOUNT_AGENT: '/account/agent/subaccount',
    PASSWORD_SUB_ACCOUNT: '/account/subaccount/replacePassword',
    PROFILE_AGENT: '/account/agent/profile',
    LOGIN_HISTORY_AGENT: '/session/agent/history',
    CHANGE_PASSWORD_AGENT: '/account/agent/changePassword',
    CHANGE_CHILD_AGENT_PASSWORD: '/account/agent/changeChildPassword',
    PROFILE_MEMBER: '/account/member/profile',
    CHILDREN: '/account/children',
    AGENT: '/account/agent',
    AGENT_KEY: '/account/agent/apiKey',
    AGENT_NEW: '/account/agentnew',
    CALLBACK: '/account/callback',
    WHITELIST: '/account/whitelist',
    AGENT_LIST: '/account/agent-user',
    MEMBER: '/account/member',
    GET_HISTORY_AGENT: '/account/agent/history',
    GET_HISTORY_MEMBER: '/account/member/history',
    SHAREHOLDER: '/account/shareholder',
    ROYALTY_SETTING: '/account/getRoyaltySetting',
    GET_API_LICENSE_BY_ID: '/account/getApiLicense',
    UPDATE_PROFILE: '/account/updateProfile',
    GET_ROYALTY_SETTING_LIMIT: '/account/getRoyaltySettingLimit',
    STATIC_CONFIG: '/static-config',
    GET_PRODUCT: '/static-config/product',
    DEPOSIT: '/wallet/deposit',
    WITHDRAWAL: '/wallet/withdraw',
    SEAMLESS_DEPOSIT: '/wallet/seamless/deposit',
    SEAMLESS_WITHDRAWAL: '/wallet/seamless/withdraw',
    TRANSFER_DEPOSIT: '/wallet/transfer/deposit',
    TRANSFER_WITHDRAWAL: '/wallet/transfer/withdraw',
    CREDIT_HISTORY: '/wallet/history/credit',
    GET_STATEMENT: '/wallet/history/statement',
    GET_REPORT: '/report/winLose',
    GET_BET_DETAIL: '/report/betDetail',
    GET_TOURNAMENT_WINLOSE: '/report/tournamentWinLose',
    GET_TOURNAMENT_REWARD: '/report/tournamentReward',
    GET_BET_DETAIL_GAME: '/game/betDetail',
    GET_DASHBOARD: '/dashboard/info',
    GET_DASHBOARD_GRAPH: '/dashboard/graph_wl',
    GET_DASHBOARD_TYPES: '/dashboard/infoEachType',
    LOG_REQUEST: '/log-request',
    GET_GAME_RUNNING: '/log-request/running',
    GET_RECALCULATE_LOG: '/log-request/recalculate',
    RETRY_OUTSTANDING: '/log-request/retry-message',
    GET_MAINTENANCE: '/maintenance/getLastMaintenance',
    SAVE_MAINTENANCE: '/maintenance/maintenance',
    TOURNAMENT_LIST:'/tournament/list',
    TOURNAMENT_INFO: '/tournament/tournament-info',
    TOURNAMENT_DELETE: '/tournament/delete'
}

