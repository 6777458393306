var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "footer",
      {
        staticClass:
          "footer d-flex flex-column flex-md-row align-items-center justify-content-end"
      },
      [
        _c("p", { staticClass: "text-muted text-center text-md-left" }, [
          _vm._v(" Copyright © 2021 "),
          _c("span", { staticClass: "font-weight-bolder" }, [
            _vm._v("IAM Poker")
          ]),
          _vm._v(". All rights reserved ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }