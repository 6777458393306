













import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/Header/Navbar/Navbar.component.vue";
import SideBar from "@/components/Header/Sidebar/Sidebar.component.vue";
import FooterBar from "@/components/Footer/Footer.component.vue";
import { GET_PROFILE_AGENT } from "../api/account";

@Component({
  components: {
    NavBar,
    SideBar,
    FooterBar
  }
})
export default class App extends Vue {
  displayComponent: any = "none";
  loadingComponent: any = "none";
  dataActive: any = false;
  loadingActive: any = false;

  async updated() {
    await this.setDisplay();
    await this.setComponetLoading();
  }

  async setDisplay() {
    if (this.$route.name !== "Login" && this.$route.name !== "BetDetail") {
      this.displayComponent = "block";
      this.dataActive = true;
    }
  }

   async mounted() {
    const login = Vue.$cookies.get("login");
    if (login) {
      const profile = await GET_PROFILE_AGENT(login.username);
      this.$store.dispatch("addProfile", profile[0]);
    }
  }

  async setComponetLoading() {
    this.loadingComponent = "block";
    this.loadingActive = true
  }
}
