import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import VueCookies from 'vue-cookies'
import store from "@/store";

Vue.use(VueCookies)

const Login = () => import('../components/Login/Login.component.vue')
const Home = () => import('../components/Home/Home.component.vue')
const AgentList = () => import('../components/Agent/AgentList/AgentList.component.vue')
const AddAgent = () => import('../components/Agent/AddAgent/AddAgent.component.vue')
const EditAgent = () => import('../components/Agent/EditAgent/EditAgent.component.vue')
const AddMember = () => import('../components/Member/AddMember/AddMember.component.vue')
const EditMember = () => import('../components/Member/EditMember/EditMember.component.vue')
const WinLoseComponent = () => import('../components/Report/WinLose/WinLose.component.vue')
const ChangePassword = () => import('../components/ChangePassword/ChangePassword.component.vue')
const BetDetail = () => import('../components/Report/BetDetail/BetDetail.component.vue')
const PageNotFound = () => import('../components/Error/404/404.component.vue')
const Profile = () => import('../components/Profile/Profile.component.vue')
const Transfer = () => import('../components/Payment/Transfer/Transfer.component.vue')
const Statement = () => import('../components/Payment/Statement/Statement.component.vue')
const Feedback = () => import('../components/Feedback/Feedback.component.vue')
const Retry = () => import('../components/Retry/Retry.component.vue')
const Outstanding = () => import('../components/Outstanding/Outstanding.component.vue')
const Recalculate = () => import('../components/ReCalculate/ReCalculate.component.vue')
const SubAccountList = () => import('../components/SubAccount/SubAccountList/SubAccountList.component.vue')
const AddSubAccount = () => import('../components/SubAccount/AddSubAccount/AddSubAccount.component.vue')
const EditSubAccount = () => import('../components/SubAccount/EditSubAccount/EditSubAccount.component.vue')
const Maintenance = () => import('../components/Maintenance/Maintenance.component.vue')
const TournamentList = () => import('../components/Tournament/TournamentList/TournamentList.component.vue')
const AddTournament = () => import('../components/Tournament/AddTournament/AddTournament.component.vue')
const EditTournament = () => import('../components/Tournament/EditTournament/EditAgent.component.vue')

const TournamentWinLose = () => import('../components/Report/TournamentWinLose/TournamentWinLose.component.vue')
const TournamentRewards = () => import('../components/Report/TournamentRewards/TournamentRewards.component.vue')

Vue.use(VueRouter)

const login = Vue.$cookies.get("login");
let mainAccount = false;
let state: any = {}
if (login) {
    mainAccount = Object.prototype.hasOwnProperty.call(login, 'mainUsername') ? false : true
    state = store.state.profile
}

const routes: Array<RouteConfig> = [
    {
        path: "*",
        name: '404',
        component: PageNotFound,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/report/winLose',
        name: 'WinLose',
        component: WinLoseComponent,
        beforeEnter: (to, from, next) => {
            next()
        }
    },
    {
        path: '/report/betdetail',
        name: 'BetDetail',
        component: BetDetail
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/agent/agent-member-list',
        name: 'AgentList',
        component: AgentList,
        beforeEnter: (to, from, next) => {
            if (mainAccount) {
                next()
            } else {
                state.subAccountMenus.find(val => {
                    if (val.name == 'MENU_AGENT') {
                        if (val.permission == 'hidden') {
                            next({ name: '404' })
                        } else {
                            next()
                        }
                    }
                });
            }
        }
    },
    {
        path: '/agent/add-agent-new',
        name: 'AddAgent',
        component: AddAgent,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company' || login.roles == 'Shareholder' || login.roles == 'Master Agent')) {
                if (mainAccount) {
                    next()
                } else {
                    state.subAccountMenus.find(val => {
                        if (val.name == 'MENU_AGENT') {
                            if (val.permission == 'editable') {
                                next()
                            } else {
                                next({ name: '404' })
                            }
                        }
                    });
                }
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/agent/edit-agent',
        name: 'EditAgent',
        component: EditAgent,
        beforeEnter: (to, from, next) => {

            if ((login.roles == 'Company' || login.roles == 'Shareholder' || login.roles == 'Master Agent')) {
                if (mainAccount) {
                    next()
                } else {
                    state.subAccountMenus.find(val => {
                        if (val.name == 'MENU_AGENT') {
                            if (val.permission == 'editable') {
                                next()
                            } else {
                                next({ name: '404' })
                            }
                        }
                    });
                }
            } else {
                next({ name: '404' })
            }
            
        }
    },
    {
        path: '/agent/add-member',
        name: 'AddMember',
        component: AddMember,
        beforeEnter: (to, from, next) => {
            if (login.roles == 'Master Agent') {
                if (mainAccount) {
                    next()
                } else {
                    state.subAccountMenus.find(val => {
                        if (val.name == 'MENU_AGENT') {
                            if (val.permission == 'editable') {
                                next()
                            } else {
                                next({ name: '404' })
                            }
                        }
                    });
                }
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/agent/edit-member',
        name: 'EditMember',
        component: EditMember,
        beforeEnter: (to, from, next) => {
            if (login.roles == 'Master Agent' || login.roles == 'Agent') {
                if (mainAccount) {
                    next()
                } else {
                    state.subAccountMenus.find(val => {
                        if (val.name == 'MENU_AGENT') {
                            if (val.permission == 'editable') {
                                next()
                            } else {
                                next({ name: '404' })
                            }
                        }
                    });
                }
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/payment/transfer',
        name: 'Transfer',
        component: Transfer,
        beforeEnter: (to, from, next) => {
                if (mainAccount) {
                    next()
                } else {
                    state.subAccountMenus.find(val => {
                        if (val.name == 'MENU_CREDIT') {
                            if (val.permission == 'hidden') {
                                next({ name: '404' })
                            } else {
                                next()
                            }
                        }
                    });
                }
        }
    },
    {
        path: '/payment/statement',
        name: 'Statement',
        component: Statement,
        beforeEnter: (to, from, next) => {
                if (mainAccount) {
                    next()
                } else {
                    state.subAccountMenus.find(val => {
                        if (val.name == 'MENU_CREDIT') {
                            if (val.permission == 'hidden') {
                                next({ name: '404' })
                            } else {
                                next()
                            }
                        }
                    });
                }
        }
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        beforeEnter: (to, from, next) => {
                    if (mainAccount) {
                        next()
                    } else {
                        next()
                        // state.subAccountMenus.find(val => {
                        //     if (val.name == 'MENU_ACCOUNT') {
                        //         if (val.permission == 'editable') {
                        //             next()
                        //         } else {
                        //             next({ name: '404' })
                        //         }
                        //     }
                        // });
                    }
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: (to, from, next) => {
            if (mainAccount) {
                next()
            } else {
                state.subAccountMenus.find(val => {
                    if (val.name == 'MENU_ACCOUNT') {
                        if (val.permission == 'hidden') {
                            next({ name: '404' })
                        } else {
                            next()
                        }
                    }
                });
            }
        }
    },
    {
        path: '/member/sub-account',
        name: 'SubAccountList',
        component: SubAccountList,
        beforeEnter: (to, from, next) => {
            if (mainAccount) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/member/add-sub-account',
        name: 'AddSubAccount',
        component: AddSubAccount,
        beforeEnter: (to, from, next) => {
            if (mainAccount) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/edit-sub-account',
        name: 'EditSubAccount',
        component: EditSubAccount,
        beforeEnter: (to, from, next) => {
            if (mainAccount) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        //feedback
        path: '/feedback',
        name: 'Feedback',
        component: Feedback,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company')) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/retry',
        name: 'Retry',
        component: Retry,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company')) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/outstanding',
        name: 'Outstanding',
        component: Outstanding,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company')) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/recalculate',
        name: 'Recalculate',
        component: Recalculate,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company')) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company')) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/tournament/list',
        name: 'TournamentList',
        component: TournamentList,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company')) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/tournament/add',
        name: 'AddTournament',
        component: AddTournament,
        beforeEnter: (to, from, next) => {
            if ((login.roles == 'Company')) {
                next()
            } else {
                next({ name: '404' })
            }
        }
    },
    {
        path: '/tournament/edit',
        name: 'EditTournament',
        component: EditTournament,
        beforeEnter: (to, from, next) => {
            next()
            // if (login.roles == 'Master Agent' || login.roles == 'Agent') {
                // if (mainAccount) {
                //     next()
                // } else {
                //     state.subAccountMenus.find(val => {
                //         if (val.name == 'MENU_AGENT') {
                //             if (val.permission == 'editable') {
                //                 next()
                //             } else {
                //                 next({ name: '404' })
                //             }
                //         }
                //     });
                // }
                // next()
            // } else {
            //     next({ name: '404' })
            // }
        }
    },
    {
        path: '/report/TournamentWinLose',
        name: 'TournamentWinLose',
        component: TournamentWinLose,
        beforeEnter: (to, from, next) => {
            next()
        }
    },
    {
        path: '/report/TournamentRewards',
        name: 'TournamentRewards',
        component: TournamentRewards,
        beforeEnter: (to, from, next) => {
            next()
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    const lang = process.env.VUE_APP_LANGUAGE
    if (store.state.locale == lang) {
        store.dispatch('changeLocale', lang)
    }
    if (to.name !== 'Login' && !login) {
        next({ name: 'Login' })
    } else if (to.name == 'Login' && login) {
        next()
    } else {
        next()
    }
})

export default router
